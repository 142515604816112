
import { Component, Vue } from 'vue-property-decorator'
import { DeviceInfo, OperationRecord } from '@/types/ecologyMonitor'
import AMapLoader from '@amap/amap-jsapi-loader'
import LocationImg from '@/assets/img/location.png'
import { mapKey } from '@/utils/config'
import { drawPolygon } from '@/utils/formatData'

type Event = {
  lnglat: {
    lat: number;
    lng: number;
    getLng: () => number;
    getLat: () => number;
  };
}
@Component
export default class DeviceDetail extends Vue {
  private activeName = 'detail'
  private deviceInfo: DeviceInfo = {
    deviceName: '',
    deviceNumber: '',
    deviceStatus: '',
    allotStatus: ''
  }

  private isEdit = false
  private editErr = ''

  private deviceId = ''
  private loading = false
  private longitude = 118.78
  private latitude = 32.07
  private marker: any = null
  private siteName = ''

  page = 1
  size = 10
  total = 0
  tableData: OperationRecord[] = []
  get deviceNumber () {
    return this.$route.query.deviceNumber
  }

  get deviceType () {
    return this.$route.query.deviceType
  }

  mounted () {
    this.getDetail()
    this.loadData()
  }

  getDetail () {
    this.loading = true
    const urlList = [
      this.$apis.maintainJobMonitor.selectMowingInfoByDeviceNumber,
      this.$apis.maintainJobMonitor.selectMowingInfoByDeviceNumber,
      this.$apis.maintainJobMonitor.selectMowingInfoByDeviceNumber,
      this.$apis.maintainJobMonitor.selectMowingInfoByDeviceNumber
    ]
    this.$axios.get(urlList[Number(this.deviceType)], {
      deviceNumber: this.deviceNumber
    }).then((res) => {
      this.deviceInfo = res
    }).finally(() => {
      this.loading = false
    })
  }

  handleClick () {
    if (this.activeName === 'position') {
      if (this.deviceInfo.longitude && this.deviceInfo.latitude) {
        this.longitude = parseFloat(this.deviceInfo.longitude)
        this.latitude = parseFloat(this.deviceInfo.latitude)
      }
      const lng = Number(this.longitude)
      const lat = Number(this.latitude)
      this.$nextTick(() => {
        this.loadMap(lng, lat)
      })
    }
  }

  loadData () {
    this.loading = true
    this.$axios.get(this.$apis.ecologyMonitor.selectDeviceAllotByPage, {
      deviceNumber: this.deviceNumber,
      page: this.page,
      size: this.size
    }).then(res => {
      this.tableData = res.list
      this.total = res.total
    }).finally(() => {
      this.loading = false
    })
  }

  onSave () {
    if (this.deviceInfo.deviceName) {
      this.editErr = ''
      this.submit()
    } else {
      this.editErr = '请输入设备名称'
    }
  }

  loadMap (lng: number, lat: number) {
    AMapLoader.load({
      key: mapKey,
      version: '2.0',
      plugins: []
    }).then(() => {
      const map = new AMap.Map('container', {
        center: [this.longitude, this.latitude],
        zoom: 15
      })
      if (lng && lat) {
        this.addMarker(map, lng, lat)
      }
      if (this.deviceInfo.projectId) {
        this.loadLocationList(map)
      } else {
        this.$message.warning('请先分配设备')
      }
      map.on('click', (e: Event) => {
        this.setLngLat(e.lnglat.getLng(), e.lnglat.getLat())
        this.addMarker(map, this.longitude, this.latitude)
      })
      // 输入提示
      const autoOptions = {
        input: 'tipinput'
      }
      AMap.plugin(['AMap.PlaceSearch', 'AMap.AutoComplete'], () => {
        const auto = new AMap.Autocomplete(autoOptions)
        auto.on('select', (e: any) => {
          map.setZoomAndCenter(16, [e.poi.location.lng, e.poi.location.lat])
        })
      })
    })
  }

  // 画电子围栏
  loadLocationList (map: AMap.Map) {
    this.$axios.get(this.$apis.project.selectYhProjectMapByProjectId, {
      projectId: this.deviceInfo.projectId
    }).then((res) => {
      // 画电子围栏
      drawPolygon(map, res.locationList, null, (e: Event) => {
        this.setLngLat(e.lnglat.getLng(), e.lnglat.getLat())
        this.addMarker(map, this.longitude, this.latitude)
      })
    })
  }

  setLngLat (lng: number, lat: number) {
    this.longitude = lng
    this.latitude = lat
  }

  addMarker (map: AMap.Map, lng: number, lat: number) {
    if (this.marker) {
      map.remove(this.marker)
    }
    this.marker = new AMap.Marker({
      map: map,
      position: new AMap.LngLat(lng, lat),
      offset: new AMap.Pixel(-20, -40),
      icon: LocationImg,
      draggable: true
    })
    this.marker.on('dragend', (e: Event) => {
      this.setLngLat(e.lnglat.getLng(), e.lnglat.getLat())
    })
    this.setLngLat(lng, lat)
    this.marker.on('dragstart', (e: Event) => {
      this.marker.setPosition(new AMap.LngLat(e.lnglat.lng, e.lnglat.lat))
    })
    map.add(this.marker)
  }

  submit () {
    this.loading = true
    const urlList = [
      this.$apis.maintainJobMonitor.updateMowingInfoLocation,
      this.$apis.maintainJobMonitor.updateMowingInfoLocation,
      this.$apis.maintainJobMonitor.updateMowingInfoLocation,
      this.$apis.maintainJobMonitor.updateMowingInfoLocation
    ]
    const info = this.deviceType === '3' ? {
      deviceNumber: this.deviceNumber,
      deviceName: this.deviceInfo.deviceName
    } : {
      deviceNumber: this.deviceNumber,
      longitude: this.longitude,
      deviceName: this.deviceInfo.deviceName,
      latitude: this.latitude
    }
    this.$axios.post(urlList[Number(this.deviceType)], { ...info }).then(() => {
      this.$message.success('保存成功')
      this.isEdit = false
    }).finally(() => {
      this.loading = false
    })
  }
}
